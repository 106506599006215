/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { tomtomApiKey } from '../../constants/config';

const FuzzySearchInput = (
    {changeValue,
    locationName,
    reset,
    funcLoaded,
    funcMessage,
    showMessage,}
) => {
  const [state, setState] = useState({
    suggestions: null,
    dropdown_open: false
  })
  const [address, setAddress] = useState("")
  const [tt, setTt] = useState(null)
  useEffect(() => {
    locationName ? setAddress(decodeURIComponent(locationName)) : setAddress("");
  }, [locationName]);

  useEffect(() => {
    if (funcLoaded) {
      funcLoaded(true);
    }
    if (locationName) {
      setAddress(locationName);
    }
    const importTT = async () => await import("@tomtom-international/web-sdk-services")
    importTT().then((tt) => {
      setTt(tt)
    })
  }, [])

  useEffect(() => {
    if (reset === true && state.address !== '') {
      setAddress('') 
    }
  }, [reset])

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setState({
            ...state,
            dropdown_open: false
          })
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  const handleChange = event => {
    if (event.target.value.length === 0) {
      changeValue('', '');
      setState({
        ...state,
        suggestions: null
      })
      funcMessage(true);
    } else {
      funcMessage(false);
        if (event.target.value)
        tt.services.fuzzySearch({
            key: tomtomApiKey(),
            typeahead: true,
            limit: 5,
            query: event.target.value
        })
        .then((response) => {
            setState({
                suggestions: response.results,
                dropdown_open: true
            })
        });
    }
    setAddress(event.target.value);
  };

  const handleSelect = (event) => {
      const coords = event.target.dataset.key.split('_')
      setState({
        dropdown_open: false
      });
      setAddress(event.target.dataset.addr)
      changeValue(
        coords[0],
        coords[1],
        event.target.dataset.addr
      );
      funcMessage(true);
  };

  return (
          <div>
            <input
              maxLength="100"
              value={address}
              onChange={handleChange}
                placeholder={'Search by location'}
                className={'location-search-input' +
                  (showMessage ? ' wrong' : '')}
            />
            {state.dropdown_open && state.suggestions && state.suggestions.length ? (
              <div className="autocomplete-dropdown-container" ref={wrapperRef}>
                {state.suggestions.map(suggestion => {
                  return (
                    <div
                        key={suggestion.position.lat}
                        className={'suggestion-item--active'}
                        style={{ backgroundColor: '#f9f9f9', cursor: 'pointer' }}
                    >
                      <li
                      onClick={handleSelect}
                      data-key={suggestion.position.lat + '_' + suggestion.position.lng}
                      data-addr={suggestion.address.freeformAddress}
                      >{suggestion.address.freeformAddress}</li>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
    )
}

FuzzySearchInput.propTypes = {
    changeValue: PropTypes.func,
    lat: PropTypes.any,
    lng: PropTypes.any,
    locationName: PropTypes.any,
    reset: PropTypes.bool,
    funcLoaded: PropTypes.func,
    funcMessage: PropTypes.func,
    showMessage: PropTypes.bool,
  };

export default FuzzySearchInput
