import React from 'react';
import Layout from '../components/layout/layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import FindATrial from '../components/search/find-a-trial';
import Img from 'gatsby-image';
import {
  LearnMoreSection,
  TextSection,
  Fonts,
  ContainerHolder,
  HomeHero,
} from '../global.css';
import Modal from '../components/modal/modal';
import Disclaimer from '../components/disclaimer/disclaimer';
import BackToTop from '../components/back-to-top/back-to-top';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      locationLoaded: null,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    let { data } = this.props;
    return (
      <div
        style={
          this.state.locationLoaded ? { display: 'block' } : { display: 'none' }
        }
      >
        <Layout meta={data.homeJson.seo}>
          <Fonts className={'homePage'}>
            <HomeHero>
              <ContainerHolder>
                <Row className={'hero-content'}>
                  <Col md={12} className={'text-box'}>
                    <h1>{data.homeJson.heroTitle}</h1>
                    <p>{data.homeJson.heroText}</p>
                  </Col>
                  <Col md={12} className={'searchBox search-home'}>
                    <FindATrial
                      navigate={this.props.navigate}
                      location={this.props.location}
                      onHome={true}
                      pageContext={this.props.pageContext}
                      funcLoaded={param => {
                        this.setState({
                          locationLoaded: param,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </ContainerHolder>
            </HomeHero>

            <LearnMoreSection>
              <Row className={'main-row'}>
                {data.homeJson.learnMoreSection.map((item, index) => (
                  <Col
                    key={index}
                    md={6}
                    className={
                      index <= 1 ? 'border-padding main-col' : 'main-col'
                    }
                  >
                    <Row className={'sub-row'}>
                      <Col className={'img-holder'}>
                        <img
                          className={index === 3 ? 'infoIcon' : ''}
                          style={item.icon.publicURL.indexOf('Icon_07') > -1 ? {minWidth: '30px'}:{}}
                          src={item.icon.publicURL}
                          alt={'icon'}
                        />
                      </Col>
                      <Col className={'text-holder'}>
                        <h3>{item.title}</h3>
                        <p className={index > 2 ? 'shortText' : 'longText'}>
                          {item.text}
                        </p>
                        {index === 1 || index === 0 ? (
                          <a
                            className={'buttonStyle'}
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.btnText}
                          </a>
                        ) : (
                          <a
                            className={'buttonStyle anchor'}
                            href={item.link}
                            target={item.link.indexOf('.pdf') > -1 ? '_blank':'_self'}
                          >
                            {item.btnText}
                          </a>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </LearnMoreSection>
            <TextSection>
              {data.homeJson.textSection.map((text, index) => (
                <div
                  key={index}
                  className={'container-3gsk ' + (index === 1 ? 'griBg' : '')}
                >
                  <ContainerHolder>
                    <div className={'main-div'}>
                      <h2 className={index === 0 ? 'first-h2' : ''}>
                        <span id={`anchor0${index + 1}`}></span>
                        {text.title}
                      </h2>
                      <Row className={index === 1 ? 'paired' : ''}>
                        <Col
                          className={'text-holder'}
                          xl={
                            index === 1
                              ? { order: 1, offset: 0, size: 5 }
                              : { order: 0, offset: 1, size: 5 }
                          }
                          lg={
                            index === 1
                              ? { order: 1, offset: 0, size: 5 }
                              : { order: 0, offset: 1, size: 5 }
                          }
                          md={{ order: 1, offset: 2, size: 8 }}
                          sm={{ order: 2, offset: 0, size: 12 }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: text.content.childMarkdownRemark.html,
                            }}
                          />
                          {index === 0 ? (
                            <Modal
                              button={'Learn More'}
                              hideModal={this.hideModal}
                              showModal={this.showModal}
                              open={this.state.modalOpen}
                              type={'learnMore'}
                            >
                              <Disclaimer hideModal={this.hideModal} />
                            </Modal>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col
                          className={'img-holder'}
                          lg={
                            index === 1
                              ? { offset: 1, size: 5 }
                              : { offset: 0, size: 5 }
                          }
                          md={{ offset: 2, size: 8 }}
                          sm={{ offset: 0, size: 12 }}
                        >
                          <Img
                            fluid={text.img.childImageSharp.fluid}
                            alt={text.altText}
                          />
                        </Col>
                      </Row>
                    </div>
                  </ContainerHolder>
                </div>
              ))}
            </TextSection>
          </Fonts>
          <BackToTop />
        </Layout>
      </div>
    );
  }
}

export default Index;
Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func,
};

export const query = graphql`
  query HomepageQuery {
    homeJson {
      heroTitle
      heroText
      seo {
        pageTitle
        pageDescription
      }
      textSection {
        title
        img {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
        altText
      }
      learnMoreSection {
        title
        text
        icon {
          publicURL
        }
        link
        btnText
      }
    }
  }
`;
