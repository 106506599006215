/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Formik } from 'formik';
import { FindATrialstyle } from '../../global.css';
import {
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import Autocomplete from '../autocomplete/autocomplete';
import LocationAutocomplete from '../location-autocomplete/autocomplete';
import FuzzySearchInput from '../location-autocomplete/fuzzy-search';
import Checkbox from '../checkbox/checkbox';
import { FaAngleRight } from 'react-icons/fa';
import { navigate } from '@reach/router';
import { Popover } from '../checkbox/checkbox.css';
import { MasterDataContext } from '../../store/masterDataContext';

class FindATrial extends React.Component {
  static contextType = MasterDataContext;

  constructor(props) {
    super(props);
    this.state = {
      keySugestion: [],
      dropdownOpen: false,
      distance: '',
      formInitialized: false,
      locationField: true,
      showAlert: false,
    };
    this.searchParams = null;
    this.userCountry = null;
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  prevMasterData = null;

  componentDidUpdate() {
    if (this.state.reset === true) {
      this.setState({ reset: false });
    }

    if (this.context) {
      const { masterData } = this.context;

      if (masterData !== this.prevMasterData) {
        this.prevMasterData = masterData;

        let keySugestion = [];
        if (
          typeof window !== 'undefined' &&
          masterData &&
          Array.isArray(masterData.CONDITION) &&
          Array.isArray(masterData.PRODUCT)
        ) {
          masterData.CONDITION.forEach(element => {
            keySugestion.push(element.DisplayValue);
            if (element.Children) {
              element.Children.forEach(subElement =>
                keySugestion.push(subElement.DisplayValue)
              );
            }
            masterData.PRODUCT.forEach(element => {
              if (!keySugestion.includes(element.DisplayValue))
                keySugestion.push(element.DisplayValue);
            });
          });
          this.setState({
            keySugestion: keySugestion,
            mounted: true,
          });
          this.userCountry =
            window.localStorage.getItem('test-user-country') ||
            window.localStorage.getItem('user-country');
        }
      }
    }
  }

  componentDidMount() {
    try {
      this.searchParams = new URL(location.href).searchParams;
    } catch (e) {
      this.searchParams = new URLSearchParams('');
    }
    if (this.searchParams) {
      this.setState({ formInitialized: true });
    }
    let { data } = this.props;
    if (
      typeof window !== 'undefined' &&
      window.location.pathname.includes('results') &&
      this.searchParams.get('Latitude') &&
      this.searchParams.get('Latitude').length > 1
    ) {
      let distance = this.searchParams.get('MileRadius');
      this.setState({
        distance: data.advancedSearchJson.distance.filter(item =>
          item.key.includes(distance)
        )[0].value,
      });
    }

    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    let keySugestion = [];
    if (
      typeof window !== 'undefined' &&
      masterData &&
      Array.isArray(masterData.CONDITION) &&
      Array.isArray(masterData.PRODUCT)
    ) {
      masterData.CONDITION.forEach(element => {
        keySugestion.push(element.DisplayValue);
        if (element.Children) {
          element.Children.forEach(subElement =>
            keySugestion.push(subElement.DisplayValue)
          );
        }
        masterData.PRODUCT.forEach(element => {
          if (!keySugestion.includes(element.DisplayValue))
            keySugestion.push(element.DisplayValue);
        });
      });
      this.setState({
        keySugestion: keySugestion,
        mounted: true,
      });
      this.userCountry =
        window.localStorage.getItem('test-user-country') ||
        window.localStorage.getItem('user-country');
    }
  }

  handleSubmit(values) {
    let { location, searchCallBack } = this.props,
      searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }
    let finalQuery = {};
    searchParams.forEach((value, key) => {
      finalQuery[key] = value;
    });
    Object.keys(values).forEach(key => {
      finalQuery[key] = values[key];
    });
    let newSearchQuery = Object.keys(finalQuery)
      .map(
        k =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(
            Array.isArray(finalQuery[k])
              ? finalQuery[k].join('~')
              : `${finalQuery[k]}`
          )
      )
      .join('&');
    if (newSearchQuery && `?${newSearchQuery}` !== window.location.search) {
      navigate(`/en/results/?${newSearchQuery}`, { replace: true });
      if (searchCallBack && typeof searchCallBack === 'function') {
        setTimeout(() => {
          searchCallBack();
        });
      }
    }
  }

  render() {
    let { data, onResults, onHome } = this.props;
    return (
      <FindATrialstyle className={onResults ? 'results' : ''}>
        {this.searchParams && (
          <Formik
            enableReinitialize={false}
            initialValues={
              onHome
                ? {
                    Keyword:
                      (this.searchParams && this.searchParams.get('Keyword')) ||
                      '',
                    Status: (this.searchParams &&
                      this.searchParams.get('Status') &&
                      this.searchParams.get('Status').split('~')) || [
                      'RECSTATUS_RECRUITING~RECSTATUS_NOT_YET_RECRUITING',
                    ],
                    Latitude:
                      (this.searchParams &&
                        this.searchParams.get('Latitude') &&
                        this.searchParams.get('Latitude').split('~')) ||
                      '',
                    Longitude:
                      (this.searchParams &&
                        this.searchParams.get('Longitude') &&
                        this.searchParams.get('Longitude').split('~')) ||
                      '',
                    LocationName:
                      (this.searchParams &&
                        this.searchParams.get('LocationName') &&
                        this.searchParams.get('LocationName').split('~')) ||
                      '',
                  }
                : {
                    MileRadius:
                      (this.searchParams &&
                        this.searchParams.get('MileRadius')) ||
                      '',
                    Keyword:
                      (this.searchParams && this.searchParams.get('Keyword')) ||
                      '',
                    Latitude:
                      (this.searchParams &&
                        this.searchParams.get('Latitude') &&
                        this.searchParams.get('Latitude').split('~')) ||
                      '',
                    Longitude:
                      (this.searchParams &&
                        this.searchParams.get('Longitude') &&
                        this.searchParams.get('Longitude').split('~')) ||
                      '',
                    LocationName:
                      (this.searchParams &&
                        this.searchParams.get('LocationName') &&
                        this.searchParams.get('LocationName').split('~')) ||
                      '',
                  }
            }
            onSubmit={(values, actions) => {
              if (this.state.locationField) {
                this.handleSubmit(values);
                actions.setSubmitting(true);
                if (
                  values.Latitude !== '' &&
                  values.Longitude !== '' &&
                  values.MileRadius !== '' &&
                  values.LocationName !== '' &&
                  data.advancedSearchJson.distance.filter(
                    item => item.key === values.MileRadius
                  ).length > 0
                ) {
                  let distance = values.MileRadius;
                  this.setState({
                    distance: data.advancedSearchJson.distance.filter(
                      item => item.key === distance
                    )[0].value,
                  });
                } else {
                  this.setState({
                    distance: '',
                  });
                }
                setTimeout(() => {
                  this.props.pageSearch ? this.props.pageSearch() : null;
                }, 1000);
              } else {
                this.setState({
                  showAlert: true,
                });
              }
            }}
            render={props => (
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <div className={'search-section'}>
                  <Row>
                    <Col
                      lg={onResults ? 4 : 6}
                      md={6}
                      sm={12}
                      className={'keyword-section'}
                    >
                      <h4>
                        {data.advancedSearchJson.search.keySearch}
                        <img src={'/icons/tooltip.svg'} alt={''} id="keyword" />
                      </h4>
                      <Popover
                        placement="top"
                        trigger="hover"
                        modifiers={{ flip: { behavior: ['bottom'] } }}
                        target="keyword"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              data.advancedSearchJson.tooltips.keySearch
                                .childMarkdownRemark.html,
                          }}
                        />
                      </Popover>
                      <Autocomplete
                        placeholder={data.advancedSearchJson.search.keySearchPH}
                        suggestions={this.state.keySugestion}
                        name="Keyword"
                        changeValue={value => {
                          props.setFieldValue('Keyword', value);
                        }}
                        userInput={props.values.Keyword}
                      />
                    </Col>
                    <Col
                      lg={onResults ? 4 : 4}
                      md={onResults ? 6 : 4}
                      sm={12}
                      className={'location-section'}
                    >
                      <h4>{data.advancedSearchJson.search.location}</h4>
                      {this.userCountry === 'CN' ? (
                        <FuzzySearchInput
                          lat={props.values.Latitude}
                          lng={props.values.Longitude}
                          locationName={props.values.LocationName}
                          changeValue={(lat, long, locationName) => {
                            props.setFieldValue(['Latitude'], lat);
                            props.setFieldValue(['Longitude'], long);
                            props.setFieldValue(['LocationName'], locationName);
                            props.setFieldValue('MileRadius', '100');
                            this.setState({
                              distance: '100 mi (160 km)',
                            });
                            if (lat.length === 0 && long.length === 0) {
                              props.setFieldValue('MileRadius', '');
                              this.setState({
                                distance: '',
                              });
                            }
                          }}
                          funcLoaded={param => {
                            if (this.props.funcLoaded) {
                              this.props.funcLoaded(param);
                            }
                          }}
                          showMessage={this.state.showAlert}
                          funcMessage={param => {
                            if (param === false) {
                              this.setState({
                                locationField: param,
                              });
                            } else {
                              this.setState({
                                locationField: param,
                                showAlert: false,
                              });
                            }
                          }}
                        />
                      ) : (
                        <LocationAutocomplete
                          lat={props.values.Latitude}
                          lng={props.values.Longitude}
                          locationName={props.values.LocationName}
                          changeValue={(lat, long, locationName) => {
                            props.setFieldValue(['Latitude'], lat);
                            props.setFieldValue(['Longitude'], long);
                            props.setFieldValue(['LocationName'], locationName);
                            props.setFieldValue('MileRadius', '100');
                            this.setState({
                              distance: '100 mi (160 km)',
                            });
                            if (lat.length === 0 && long.length === 0) {
                              props.setFieldValue('MileRadius', '');
                              this.setState({
                                distance: '',
                              });
                            }
                          }}
                          funcLoaded={param => {
                            if (this.props.funcLoaded) {
                              this.props.funcLoaded(param);
                            }
                          }}
                          showMessage={this.state.showAlert}
                          funcMessage={param => {
                            if (param === false) {
                              this.setState({
                                locationField: param,
                              });
                            } else {
                              this.setState({
                                locationField: param,
                                showAlert: false,
                              });
                            }
                          }}
                        />
                      )}
                      {this.state.showAlert ? (
                        <p className={'warning-message'}>
                          {'Please select location'}
                        </p>
                      ) : null}
                    </Col>
                    {onHome ? (
                      ''
                    ) : (
                      <Col
                        xl={onResults ? 2 : 3}
                        lg={onResults ? 2 : 3}
                        md={6}
                        sm={12}
                        className={'distance-section'}
                      >
                        <h4>
                          {data.advancedSearchJson.search.distance}
                          <img
                            src={'/icons/tooltip.svg'}
                            alt={''}
                            id="distance"
                          ></img>
                        </h4>
                        <Popover
                          placement="top"
                          trigger="hover"
                          modifiers={{ flip: { behavior: ['bottom'] } }}
                          target="distance"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.advancedSearchJson.tooltips.distance
                                  .childMarkdownRemark.html,
                            }}
                          />
                        </Popover>
                        <Dropdown
                          isOpen={this.state.dropdownOpen}
                          toggle={this.toggleDropdown.bind(this)}
                          className={'select-dropdown'}
                        >
                          <DropdownToggle
                            disabled={
                              props.values.Latitude.length < 1 &&
                              props.values.Longitude.length < 1
                            }
                            tag="button"
                            type="button"
                          >
                            <div className={'ellipsis'}>
                              {this.state.distance || (
                                <p>{data.advancedSearchJson.search.distance}</p>
                              )}
                              <img src="/icons/dropdown_arrow.svg" alt="icon" />
                            </div>
                          </DropdownToggle>
                          <DropdownMenu
                            flip={false}
                            direction="down"
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: 'auto',
                                      maxHeight: 300,
                                    },
                                  };
                                },
                              },
                            }}
                          >
                            {data.advancedSearchJson.distance.map(item => (
                              <DropdownItem
                                onClick={() => {
                                  this.setState({ distance: item.value });
                                  props.setFieldValue('MileRadius', item.key);
                                }}
                                key={item.key}
                                value={item.value}
                              >
                                {item.value}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    )}
                    <Col
                      lg={onResults ? 2 : 2}
                      md={onResults ? 6 : 2}
                      sm={12}
                      className={onResults ? 'resultsAction' : 'action-section'}
                    >
                      <button className={'searchBTN'}>
                        {data.advancedSearchJson.search.search}
                      </button>
                    </Col>
                    {onResults ? (
                      ''
                    ) : (
                      <Col lg={6} md={12} sm={12} className={'status-section'}>
                        <Checkbox
                          name="Status"
                          value={
                            'RECSTATUS_RECRUITING~RECSTATUS_NOT_YET_RECRUITING'
                          }
                          id={
                            'RECSTATUS_RECRUITING~RECSTATUS_NOT_YET_RECRUITING'
                          }
                          displayvalue={
                            'Search only studies that are recruiting or will be recruiting'
                          }
                        />
                      </Col>
                    )}
                    {onResults ? (
                      ''
                    ) : (
                      <Col className={'moreSearch'} lg={6} md={12} sm={12}>
                        <p>{'For a more refined search use:'}</p>
                        <a href="/en/advanced-study-search/">
                          {'Advanced Search'}
                          <FaAngleRight />
                        </a>
                      </Col>
                    )}
                  </Row>
                </div>
              </form>
            )}
          />
        )}
      </FindATrialstyle>
    );
  }
}

FindATrial.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.any,
  navigate: PropTypes.func,
  location: PropTypes.object,
  onResults: PropTypes.bool,
  onHome: PropTypes.bool,
  searchCallBack: PropTypes.func,
  pageSearch: PropTypes.func,
  funcLoaded: PropTypes.func,
};

const FindATrialQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        advancedSearchJson {
          search {
            keySearch
            keySearchPH
            location
            locationPH
            search
            distance
          }
          tooltips {
            distance {
              childMarkdownRemark {
                html
              }
            }
            keySearch {
              childMarkdownRemark {
                html
              }
            }
          }
          distance {
            key
            value
          }
        }
      }
    `}
    render={data => <FindATrial data={data} {...props} />}
  />
);

export default FindATrialQuery;
