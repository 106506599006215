import styled from 'styled-components';
import {activeColor, fontColor, hoverColor} from "../../constants/theme";


export const DisclaimerContent = styled.div`
  padding: 0px 0px 20px 0px;
  h3 {
    color: ${fontColor};
    font-size: 28px;
    font-weight: bold;
    line-height: 28px;
  }
  p {
    color: ${fontColor};
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }
  svg {
    color: ${activeColor} !important;
    height: 24px;
    width: 24px;
    &:hover {
      color: ${hoverColor} !important;
    }
  }
  .close-icon {
    width: 100%;
    display: inline-block;
  }
  .content-modal {
    padding: 0 20px;
  }
`;
